import {ExtendedNavigationView} from "./extendedNavigationView";
import {fhirEnums} from "../resources/classes/fhir-enums";
import {FhirService} from "../resources/services/FhirService";
import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import {NitTools} from "../resources/classes/NursitTools";
import {ChangePassword} from "./changePassword";
import * as Fhir from 'resources/classes/FhirModules/Fhir';
import {ConfigService} from "../resources/services/ConfigService";

// ie: http://localhost:3000/?stay=1&nextPage=assessment&embedded=1&mobile=0/#/visitExtended/7835758/debug2/debug2/debug2

export class visitView extends ExtendedNavigationView {
    ready: boolean = false;
    encounter: any;

    _encounterId: string = undefined;

    get encounterId() {
        return this._encounterId;
    }

    set encounterId(value) {
        this._encounterId = value;

        if (value) {
            this.processEncounterquery();
        }
    }

    get baseUrl(): string {
        return FhirService.Endpoint;
    }

    async activate(params) {
        this.debug(`<b>VisitExtended started.</b><br />Loading User...`);
        await super.activate(params);
        this.encounterId = params.id;
    }

    async attached() {
        await super.attached();
        this.ready = true;
        this.processEncounterquery();
    }

    getEncounter(id: string) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                await this.encounterService.pump(id);

                this.encounter = undefined;
                let sIdentifierUrl = `${fhirEnums.ResourceType.encounter}?identifier=${id}`;
                if (ConfigService.Debug)
                    this.debug("Searching for Encounter by identifier " + sIdentifierUrl);
                else
                    this.errorString = "<p style='font-weight:bold'>Patient wird aufgerufen ...</p>";

                this.fhirService.fetch(sIdentifierUrl)
                    .then((result: any[]) => {
                        if (result.length > 0) {
                            this.debug("<span style='color:#47924d'>Encounter found by identifier " + this.encounterId + "</span><br />");
                            this.encounter = result[0];
                            resolve(result[0]);
                            return result[0];
                        }

                        sIdentifierUrl = `${fhirEnums.ResourceType.encounter}/${id}`;
                        this.debug("<span style='color:orange'>Encounter by identifier not found, trying _id=" + sIdentifierUrl + "</span><br />");
                        this.fhirService.get(`${fhirEnums.ResourceType.encounter}/${id}`, true)
                            .then((result2: any) => {
                                if (typeof result2 !== "undefined") {
                                    this.debug("<span style='color:#47924d'>Encounter found by id " + this.encounterId + "</span><br />");
                                    resolve(result2);
                                    this.encounter = result2;
                                    return result2;
                                }

                                let msg = ("<span style='color:red'>Encounter not found neither with _id not with identifier = " + id + "</span><br />");
                                // this.debug(msg);
                                // this.errorString = JSON.stringify(msg);
                                reject(msg);
                                return;
                            })
                            .catch(error => {
                                if (error?.statusCode === 404) {
                                    console.warn(error);
                                    this.errorString = ''; // "Encounter does not exist";
                                    const message = this.i18n.tr('encounter_not_found', {id: id});
                                    let html = `<label style="padding: .25em;" class="label-warning">${message}</label>`;
                                    this.errorString = html;
                                    return reject(); //  "Encounter does not exist");
                                } else {
                                    console.warn(error);

                                    this.errorString = this.fhirService.getErrorString(error);
                                    return reject();
                                }
                            });
                    })
                    .catch(error => {
                        if (error) {
                            console.warn(error);
                            if (String(error).indexOf('<') === 0)
                                this.errorString = String(error);
                            else
                                this.errorString = JSON.stringify(error);
                        }

                        return reject(error);
                    });
            } catch (e) {
                if (e) {
                    if (String(e).indexOf('<') === 0)
                        this.errorString = String(e);
                    else
                        this.errorString = e.message || JSON.stringify(e);
                }

                reject(e);
            }
        });
    }

    async doGoToPatient() {
        RuntimeInfo.IsLoading = true;
        this.router.navigateToRoute("encounter", {id: this.encounter.id});
    }

    goToPatient(encounter) {
        const ps = NitTools.GetUrlParams();
        console.warn("Found Params:", ps);
        if (ps.nextPage)
            sessionStorage.setItem("nextPage", ps.nextPage);

        try {
            if (encounter.subject && encounter.subject.reference) {
                // let newUrl = "#/Encounter/" + ref;
                if (window.location.href.indexOf("stay=1") === -1) {
                    this.doGoToPatient();
                } else {
                    this.errorString = "<br /><p><b>staying on page because of url param 'stay'</b></p>";
                    let newUrl = `${window.location.href.split('#')[0].replace("stay=1", "stay=0")}#/Encounter/${encounter.id}`;
                    this.errorString = `<div class="width-100 text-center"><a class="btn btn-default" href="${newUrl}">Go to resulting page</a></div>`;
                }
            } else {
                if (!encounter.subject) {
                    this.errorString = "Encounter has no subject.";
                } else {
                    if (!encounter.subject.reference) {
                        this.errorString = "Encounter.Subject has no reference";
                    }
                }
            }
        } catch (e) {
            this.errorString = e.message || JSON.stringify(e);
        }
    }

    navigateToEncounter() {
        this.getEncounter(this.encounterId)
            .then(encounter => {
                // this.debug("Encounter found ", encounter);
                this.debug("calling goToPatient");

                if (typeof encounter !== "undefined")
                    // HomeList.gotoEncounter(encounter.id);
                    this.goToPatient(encounter);
                return;
            })
            .catch(error => {
                if (error) {
                    this.errorString = JSON.stringify(error);
                    console.warn(error);
                }

                return;
            });
    }

    showChangeUserPasswordDialog() {
        this.dialogService.open({
            viewModel: ChangePassword,
            lock: true,
            model: {
                isNewUser: true,
                userId: this.userService.userId
            }
        })
            .whenClosed(() => {
                this.needsNewPassword = false;
                this.dialogMessages.prompt(this.i18n.tr('your_password_has_been_changed'))
                    .whenClosed(() => {
                        if (this.userService.practitionerRole) {
                            let ext = Fhir.Tools.GetOrCreateExtension(this.userService.practitionerRole, 'authorization/needsPasswordChange', false);
                            if (ext) {
                                ext.valueString = "false";
                                this.fhirService.update(this.userService.practitionerRole)
                                    .catch(error => {
                                        console.warn(error.message || JSON.stringify(error));
                                    })
                                    .finally(() => {
                                        this.navigateToEncounter();
                                    });
                            }
                        }
                    })
                    .catch(error => {
                        console.warn(error.message || JSON.stringify(error));
                    });
            })
            .catch(error => {
                console.warn(error.message || JSON.stringify(error));
            });
    }

    processEncounterquery() {
        console.debug("processEncounterquery reached!");
        if (!this.ready || !this.userService.isLoggedIn) return;
        this._errorString = '';
        try {

            this.debug(`<p>User:<b>${this.userService.fullNameOrUsername}</b></br> 
                        User-Id: <b>${this.userService.username}</b></p>`);

            this.needsNewPassword = this.needsNewPassword || NitTools.ParseBool(Fhir.Tools.GetExtensionValueFromUrl(this.userService.practitionerRole, 'authorization/needsPasswordChange'));

            if (this.needsNewPassword)
                this.showChangeUserPasswordDialog();
            else
                this.navigateToEncounter();
        } catch (e) {
            this.errorString = "in processEncounterQuery: " + (e.message || JSON.stringify(e));
            return;
        }
    }

}
