import {inject} from "aurelia-framework";
import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import * as environment from "../../config/environment.json";
import {FhirService} from "../resources/services/FhirService";
import {ConfigService} from "../resources/services/ConfigService";
import {UserService} from "../resources/services/UserService";
import {LocationService} from "../resources/services/LocationService";

@inject(UserService)
export class WardShiftReport {
    iframeUrl;
    wardId;
    userService;
    ward;
    config;

    constructor(userService: UserService) {
        this.userService = userService;
    }

    activate(params) {
        if (params["id"]) {
            this.wardId = params["id"];

            this.ward = LocationService.LocationById(this.wardId);
        }
    }


    async attached() {
        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.WardShiftReport);

        if (!this.config || !this.config.settings || !this.config.settings.url) {
            return;
        }

        RuntimeInfo.TogglePatientListLG(true);

        const loginData = sessionStorage.getItem(environment.sessionName);

        const query = {
            login: loginData,
            server: FhirService.Endpoint,
            locationId: this.wardId,
            practitionerId: '',
            practitionerName: ''
        };

        if (this.userService.practitioner) {
            query.practitionerId = this.userService.practitioner.id;
            query.practitionerName = this.userService.fullNameOrUsername;
        }

        this.iframeUrl = `${this.config.settings.url}?` + Object.keys(query).map((key) => {
            return `${key}=${encodeURIComponent(query[key])}`;
        }).join('&');
    }

    detached() {
        RuntimeInfo.TogglePatientListLG(false);
    }
}
